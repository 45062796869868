import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import useHttp from "Hooks/useHttp";
import axios from "axios";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";

const UpdateFiles = () => {
  const fileInputRef1 = useRef();
  const fileInputRef2 = useRef();
  const fileInputRef3 = useRef();
  const [uploadProgress, setUploadProgress] = useState(0);
  const API = process.env.REACT_APP_API_URL;
  const [UploadedFile, setUploadedFile] = useState(null);
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };
  const handleContent = async (type) => {
    setUploadProgress(0);

    if (!UploadedFile) {
      toast.error("Please select file!");
      return;
    }

    const formData = new FormData();
    formData.append("image", UploadedFile);
    formData.append("type", type);

    try {
      const response = await axios.post(API + "users/uploadContent", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      fileInputRef1.current.value = "";
      fileInputRef2.current.value = "";
      fileInputRef3.current.value = "";
      setUploadedFile(null);
      if (response.status === 200 && response.data.responseCode === 1) {
        toast.success(response.data.responseText);
        setTimeout(() => {
          setUploadProgress(0);
        }, 500);
      } else {
        toast.error(response.data.responseText);
      }
    } catch (error) {
      toast.error(error);
      // Handle network or other errors gracefully
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className="flex h-[200px] w-full items-center justify-between gap-5 bg-brandLinear pl-3"
        style={{ zIndex: 9999 }}
      >
        <div className="flex flex-col gap-5">
          <label className="d-block text-white">Upload For PC</label>
          <input
            type="file"
            onChange={handleChange}
            ref={fileInputRef1}
            class="text-slate-500 file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 block w-full text-sm file:mr-4 file:rounded-full file:border-0 file:py-2 file:px-4 file:text-sm file:font-semibold"
          />

          <button
            onClick={() => handleContent("PC")}
            className="d-block w-50p rounded-full bg-gray-700 text-white"
          >
            Upload
          </button>
        </div>
        <div className="flex flex-col gap-5">
          <label className="d-block text-white">Upload For Mobile</label>
          <input
            type="file"
            onChange={handleChange}
            ref={fileInputRef2}
            class="text-slate-500 file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 block w-full text-sm file:mr-4 file:rounded-full file:border-0 file:py-2 file:px-4 file:text-sm file:font-semibold"
          />
          <button
            onClick={() => handleContent("MOBILE")}
            className="d-block w-50p rounded-full bg-gray-700 text-white"
          >
            Upload
          </button>
        </div>
        <div className="flex flex-col gap-5">
          <label className="d-block text-white">Upload PC Print</label>
          <input
            type="file"
            onChange={handleChange}
            ref={fileInputRef3}
            class="text-slate-500 file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 block w-full text-sm file:mr-4 file:rounded-full file:border-0 file:py-2 file:px-4 file:text-sm file:font-semibold"
          />
          <button
            onClick={() => handleContent("PCPRINT")}
            className="d-block w-50p rounded-full bg-gray-700 text-white"
          >
            Upload
          </button>
        </div>
      </div>
      {uploadProgress > 0 ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={uploadProgress} />
        </Box>
      ) : null}
    </>
  );
};

export default UpdateFiles;
