import useHttp from "Hooks/useHttp";
import Card from "components/card";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import routes from "routes.js";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import ArrowUp from '../../../../icons/ArrowUp.jpg';
import ArrowDown from '../../../../icons/ArrowDown.jpg';
import androidImage from '../../../../icons/android.png';
import computerImage from '../../../../icons/computer.png';
import okImage from '../../../../icons/ok.png';


import { useNavigate } from "react-router-dom";
const DevelopmentTable = (props) => {
  const Data = localStorage.getItem("user");
  const [LoginUser] = useState(JSON.parse(Data));
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const API = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const isFromDashboard = location.state && location.state.fromDashboard;
  console.log(isFromDashboard)
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [CurrentPath, setCurrentPath] = React.useState("Main Dashboard");
  const [open, setOpen] = useState(false);
  const [ParentId, setParentId] = useState(null);
  const [userType, setUserType] = useState("");
  const cancelButtonRef = useRef(null);
  const { PostRequest } = useHttp();
  const [TableData, setTableData] = useState([]);
  const [callGetUserList, setcallGetUserList] = useState(false);

  const { columnsData, tableData, pageSize, setCallApi, CallApi } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  // const [data] = useState(tableData);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 100 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = pageSize;

  const isUpdatedWithin5Minutes = (updatedTime) => {
    const currentTime = new Date();
    const timeDifference = currentTime - new Date(updatedTime);
    const minutesDifference = timeDifference / (1000 * 60); // Convert milliseconds to minutes
    return minutesDifference <= 3;
  };


  const GetUserList = useCallback(async () => {
    setParentId(null);
    var userType;
    if (location.pathname === "/admin/Agent") {
      userType = "STO";
    } else if (location.pathname === "/admin/Player") {
      userType = "Agent";
    }
    var FromUserType = LoginUser?.userType;
    const ParentId_loggedIN = LoginUser?.id;
    const { data } = await PostRequest(API + "users/UsersListForAdd", { userType,ParentId:ParentId_loggedIN,FromUserType });
    if (data.responseCode === 1) {
      setTableData(data.responseData);
      // setParentId(data.responseData[0].ParentId);
    }
  }, [API, PostRequest, location.pathname]);
  
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
        setCurrentPath(routes[i].path);
      }
    }
    return activeRoute;
  };

  const CreateUser = async () => {
    if (
      username === "" ||
      password === "" ||
      name === "" ||
      ParentId === null ||
      userType === ""
    ) 
    {
      toast.error("Please fill * input fields");
      return false;
    }

    if(ParentId == 0){
      toast.error("Please Select User Type");
      return false;
    }
    const FormData = {
      username,
      password,
      name,
      userType,
      ParentId,
      email: "",
      deviceType: "",
      AccountStatus: 0,
      DeviceActive: 0,
      deviceId: "",
      DeviceActiveTime: new Date(),
    };
    const { data } = await PostRequest(API + "users/CreateUser", FormData);
    if (data.responseCode === 1) {
      GetUserList();
      setcallGetUserList(true);
      setOpen(false);
      toast.success(data.responseText);
      setUsername("");
      setPassword("");
      setName("");
      setParentId(null);
      setUserType("");
    } else {
      toast.error(data.responseText);
    }
    
  };

  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname,page]);

  // useEffect(() => {
  //   for(const item of TableData){
  //       if(item.id === LoginUser.id){
  //         setDisabled(true)
  //         break;
  //       }else{
  //         setDisabled(false)
  //       }
  //     }
  // }, [location.pathname, page, TableData, LoginUser.id,CurrentPath]);
  
  useEffect(() => {
    if (CurrentPath === "Stokez") {
      setUserType("STO");
    } else {
      setUserType(CurrentPath);
    }
    
    GetUserList();
    // }, [location, CurrentPath, GetUserList]);
  }, [location, CurrentPath,]);

  // useEffect(() => {
  //   GetUserList();
  // }, [callGetUserList]);


  useEffect(() => {
    if (callGetUserList) {
      window.location.reload();
      setcallGetUserList(false);
    }
  }, [callGetUserList]);

  const UpdateAccountStatus = async (item, value) => {

    await PostRequest(API + "users/UpdateAccountStatus", {
      username: item.username,
      AccountStatus: value,
    });
    setCallApi(!CallApi);
  };
  const BlockEditAccount = async (item) => {
    let AccountStatus;
    if (item.AccountStatus === 3) {
      AccountStatus = 2;
    } else {
      AccountStatus = 3;
    }
    await PostRequest(API + "users/UpdateAccountStatus", {
      username: item.username,
      AccountStatus,
    });
    setCallApi(!CallApi);
  };
  const DeleteAccount = async (item) => {
    await PostRequest(API + "users/DeleteAccount", {
      id: item.id,
    });
    setCallApi(!CallApi);
  };
  const updateNewDeviceId = async (item) => {
    await PostRequest(API + "users/updateNewDeviceId", {
      id: item.userDevices.id,
    });
    toast.success("User Mac Id update Successfully");
    setCallApi(!CallApi);
  };

  const handleNavigation = (id, type,username) => {
    if (type === "Agent") {
      navigate("/admin/Player", {
        state: {
          id,
          type,
          userId:username,
          isFromDashboard:isFromDashboard
        },
      });
    } else if (type === "STO") {
      navigate("/admin/Agent", {
        state: {
          id,
          type,
          userId:username,
          isFromDashboard:isFromDashboard
        },
      });
    } else if (type === "Player") {
      navigate("/admin/player-history", {
        state: {
          id,
          type,
          userId:username,
          isFromDashboard:isFromDashboard
        },
      });
    }
  };
  const CheckBlock = () => {
    if (LoginUser?.AccountStatus === 3) {
      toast.error("Access denied! Please contact Admin.");
    } else {
      if(userType == "STO" && LoginUser.username == "admin"){
        setParentId(LoginUser?.id);
      }
      setOpen(true);
    }
  };

  useEffect(() => { }, [data]);
  return (
    <>
      <ToastContainer />
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {currentRoute}
          </div>
          <button
            onClick={() => CheckBlock()}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Add {CurrentPath}
          </button>
        </div>

        <div className=" w-auto sm-max:overflow-x-scroll">
          <table
            {...getTableProps()}
            className="mt-8 w-full sm-max:table-fixed"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-300 pr-5 pb-2 text-start dark:!border-navy-700 sm-max:w-[100px]"
                      key={index}
                    >
                      <div style={{ color: 'black' }} className="text-xs tracking-wide font-black text-gray-600">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Fragment key={index}>
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        let data = "";
                        if (cell.column.Header === "ID") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === "NAME") {
                          data = (
                            <p className=" text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === "USERNAME") {
                          const deviceType = cell.row.original.deviceType.toLowerCase();
                          const time = cell.row.original.updatedAt
                          const userType = cell.row.original.userType.toLowerCase();
  
                          data = (
                            <div className="flex gap-2">
                              {userType === "player" && (
                                isUpdatedWithin5Minutes(time) ? (
                                  <img src={okImage} alt="Computer Icon" className="icon" style={{ width: '24px', height: '24px' }} />
                                ) : (
                                  // <img src={androidImage} alt="Android Icon" className="icon" style={{ width: '24px', height: '24px' }} />
                                  <div style={{ width: '16px', marginTop: '3px', height: '16px', borderRadius: '50%', backgroundColor: 'grey' }}></div>
                                )
                              )}
                              <p
                                onClick={() =>
                                  handleNavigation(
                                    cell.row.original.id,
                                    cell.row.original.userType,
                                    cell.row.original.username,
                                  )
                                }
                                className="cursor-pointer font-bold text-navy-700 dark:text-white"
                                style={{ color: "#57AAFF" }}
                              >
                                {cell.value.toUpperCase()}
                              </p>
                              {userType === "player" && (deviceType === "desktop" || deviceType === "" ? (
                                <img src={computerImage} alt="Computer Icon" className="icon" style={{ width: '24px', height: '24px' }} />
                              ) : deviceType === "android" ? (
                                <img src={androidImage} alt="Android Icon" className="icon" style={{ width: '24px', height: '24px' }} />
                              ) : null)}
                            </div>
                          );
                        } else if (cell.column.Header === "PASSWORD") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === "USERTYPE") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === "BALANCE") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        } else if (cell.column.Header === "PARENT") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {/* {cell.value != null ? cell.value.name : "ADMIN"} */}
                              {cell.row != null ? cell.row.original.parentUsername : "ADMIN"}
                            </p>
                          );
                        } else if (cell.column.Header === "TOTALBET") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {isFromDashboard ? cell.row.original.today_totalBet : 0}
                            </p>
                          );
                        } else if (cell.column.Header === "TOTALWON") {
                          data = (
                            <p className="text-sm  font-bold text-navy-700 dark:text-white">
                              {isFromDashboard ? cell.row.original.todays_winPoint : 0}
                            </p>
                          );
                        } else if (cell.column.Header === "CASINOPROFIT") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {isFromDashboard ? cell.row.original.today_casinoPoint : 0}
                            </p>
                          );
                        } else if (cell.column.Header === "ACTION") {
                          data = (
                            <div className="flex gap-x-2">
                              <button
                                className="rounded-lg  text-sm font-bold flex items-center justify-center"
                                style={{ marginRight: "-13px" }}
                                onClick={() =>
                                  UpdateAccountStatus(cell.row.original, 1)
                                }
                              >
                                <img
                                  src={ArrowUp}
                                  alt="Arrow Up"
                                  className="w-8 h-8 p-1 text-center"
                                />
                              </button>
                              <button
                                className="rounded-lg text-sm font-bold flex items-center justify-center"
                                onClick={() =>
                                  UpdateAccountStatus(cell.row.original, 0)
                                }
                              >
                                <img
                                  src={ArrowDown}
                                  alt="Arrow Down"
                                  className="w-8 h-8 p-1"
                                />
                              </button>
                              {LoginUser.userType == "Admin" && <button
                              className="rounded-lg text-sm font-bold flex items-center justify-center"
                              onClick={() => {
                                navigate(`/admin/graph?userId=${cell.row.original.username}&userType=${cell.row.original.userType}`);
                              }}
                            >
                              Graph
                            </button>}
                            </div>
                          );
                        }
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="pt-2 pb-1 pl-4 pr-2 text-[14px] w-2 border-b border-l border-r border-gray-300"
                          >
                            {data}
                          </td>
                        );
                      })}
                    </tr>
                    {index < page.length - 1 && (
                      <tr>
                        {row.cells.map((cell, index) => (
                          <td
                            key={index}
                            className="border-t border-gray-800"
                            colSpan={cell.column.Header === "ACTION" ? 7 : 1}
                          />
                        ))}
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-blue-200 px-4 py-3 sm:px-6">
                    <h1 className="text-center text-4xl font-black">
                      Create {CurrentPath}
                    </h1>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6">
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="User Name *"
                      placeholder="Username"
                      id="Username"
                      type="text"
                      defaultValue={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="Name *"
                      placeholder="Name"
                      id="name"
                      type="text"
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {CurrentPath !== "Stokez" ? (
                      <SelectField
                        variant="auth"
                        extra="mb-1"
                        label={`Select ${CurrentPath} *`}
                        placeholder="mail@simmmple.com"
                        id="email"
                        type="text"
                        defaultValue={ParentId}
                        onChange={(e) => setParentId(e.target.value)}
                        // disabled={Disabled}
                      >
                        <option selected value="0">
                              Select User Type
                            </option>
                        {TableData?.map((items, index) => {

                          return (
                            <option  value={items.id} key={index}>
                              {items.name}
                            </option>
                          );
                          
                        })}
                      </SelectField>
                    ) : null}
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="Password *"
                      placeholder="*****"
                      id="password"
                      type="password"
                      defaultValue={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                      onClick={CreateUser}
                    >
                      Register
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-400 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DevelopmentTable;
